/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import "./styles.scss";
import {
  createReportFileThunk,
  deleteReportFileThunk,
  getReportStatusThunk,
  getTargetStatusThunk,
  listNfseThunk,
  resetStatus,
  selectReportStatusState,
} from "./slices";
import { Row, Col, message, Timeline, Card, Skeleton } from "antd";
import StateStatus from "../../utils/stateStatus";

import Icons from "../../common/components/Icons";
import { HeaderDash } from "../../common/components/header";
import TargetStatusCard from "./components/target_status_card";
import RowFileReport from "./components/report_file_row";
import { formatCurrency } from "../../utils/formatters";
import DocsStatusCard from "./components/docs_status_card";
import ReportActionsMenu from "./components/report_actions_menu";
import { renderStatusIcon } from "../../utils/status";
import { getProfileThunk, loginSelector } from "../login/slice";

const TargetStatusPage = () => {
  const params = useParams();
  const reportStatusState = useSelector(selectReportStatusState);
  const loginState = useSelector(loginSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getTargetsStatus = () => {
    reportStatusState.data?.report?.targets?.forEach(target => {
        if (target?.services?.length > 0 || target?.status !== "completed") {
          dispatch(getTargetStatusThunk(target.id))
        }
    })
  }

  
  useEffect(() => {
    if (loginState.status.getProfile === StateStatus.succeeded) {
      if (!(loginState.data.profile?.is_staff || loginState.data.profile?.is_superuser)) {
        message.info("Seu usuário não tem permissão.")
        navigate("/dashboard")
      } else {
        dispatch(getReportStatusThunk(params.reportId));
      }
    } else if (loginState.status.getProfile === StateStatus.failed) {
      message.info("Realize o login para acessar esta página.")
      navigate("/")
    }
  }, [loginState.status.getProfile]);

  useEffect(() => {
    if (reportStatusState.status.listNfse === StateStatus.succeeded) {
      //
    }

    if (reportStatusState.status.listNfse === StateStatus.failed) {
      //
    }
  }, [reportStatusState.status.listNfse]);

  useEffect(() => {
    if (reportStatusState.status.retryService === StateStatus.succeeded) {
      message.success(
        "Aguarde, serviço foi colocado na fila para retentativa."
      );
      dispatch(getReportStatusThunk(params.reportId));
    }

    if (reportStatusState.status.retryService === StateStatus.failed) {
      message.error("Ocorreu um erro ao retentar o serviço.");
    }
  }, [reportStatusState.status.retryService]);

  
  useEffect(() => {
    if (reportStatusState.status.getReportStatus === StateStatus.succeeded) {
      getTargetsStatus()
    }

    if (reportStatusState.status.getReportStatus === StateStatus.failed) {
      message.error("Ocorreu um erro ao buscar report.");
    }
  }, [reportStatusState.status.getReportStatus]);


  useEffect(() => {
    if (reportStatusState.status.createReportFile === StateStatus.succeeded) {
      message.success("O arquivo a ser criado foi recebido.");
      dispatch(resetStatus())
      dispatch(getReportStatusThunk(params.reportId));
    }

    if (reportStatusState.status.createReportFile === StateStatus.failed) {
      message.error("Ocorreu um erro ao solicitar um novo arquivo.");
    }
  }, [reportStatusState.status.createReportFile]);

  useEffect(() => {
    if (reportStatusState.status.createFileTarget === StateStatus.succeeded) {
      message.success("O arquivo a ser criado foi recebido.");
      dispatch(resetStatus())
      dispatch(getReportStatusThunk(params.reportId));
    }

    if (reportStatusState.status.createFileTarget === StateStatus.failed) {
      message.error("Ocorreu um erro ao solicitar um novo arquivo.");
    }
  }, [reportStatusState.status.createFileTarget]);

  useEffect(() => {
    if (reportStatusState.status.deleteFileTarget === StateStatus.succeeded) {
      message.success("O arquivo foi deletado.");
      dispatch(resetStatus())
      dispatch(getReportStatusThunk(params.reportId));
    }

    if (reportStatusState.status.deleteFileTarget === StateStatus.failed) {
      message.error("Ocorreu um erro ao deletar o arquivo.");
    }
  }, [reportStatusState.status.deleteFileTarget]);

  useEffect(() => {
    if (reportStatusState.status.deleteReportFile === StateStatus.succeeded) {
      message.success("O arquivo foi deletado.");
      dispatch(resetStatus())
      dispatch(getReportStatusThunk(params.reportId));
    }

    if (reportStatusState.status.deleteReportFile === StateStatus.failed) {
      message.error("Ocorreu um erro ao deletar o arquivo.");
    }
  }, [reportStatusState.status.deleteReportFile]);
  
  useEffect(() => {
    if (reportStatusState.status.nfseEmit === StateStatus.succeeded) {
      message.success("Aguarde, sua Nota fiscal será emitida em minutos.");
      dispatch(resetStatus())
      dispatch(listNfseThunk(params.reportId));
      dispatch(getReportStatusThunk(params.reportId));
    }

    if (reportStatusState.status.nfseEmit === StateStatus.failed) {
      message.error("Não foi possível solicitar emissão da nota fiscal, tente novamente.");
    }
  }, [reportStatusState.status.nfseEmit]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      dispatch(getReportStatusThunk(params.reportId));
      getTargetsStatus();
    }, 3 * 60000); // 3 minutos

    dispatch(getProfileThunk());
    dispatch(listNfseThunk(params.reportId));

    return () => clearInterval(intervalId);
  }, [params.reportId]);

  return (
    <>
      <HeaderDash redirectWithNoAuth={false} />
      <div className="report-status-page">
        <div className="report-title">
          REPORT STATUS DOS PESQUISADOS
          {reportStatusState.status.getReportStatus === StateStatus.loading && (
            <Icons icon="loading" />
          )}
        </div>

        <Row
          gutter={[16, 24]}
          className="report-header"
          justify="space-between"
          align="top"
        >
          <Col xs={24} sm={24} md={8} lg={8}>
            <div className="report-info">
              <div>
                <label>Caso</label>
                <span className="font-title-16-600">
                  {reportStatusState.data?.report?.case_name}
                </span>
              </div>
              {/* <div>
                                <label>CPF/CNPJ:</label>
                                <span>{beautifyCpfCnpj(reportStatusState.data?.report?.client?.cpf_cnpj)}</span>
                            </div> */}
              <div>
                <label>Tipo</label>
                <span className="case-type-value">
                  {reportStatusState.data?.report?.case_type_display}
                </span>
              </div>
              <div>
                <label>Status</label>
                <span>
                  {renderStatusIcon(
                    reportStatusState.data?.report?.status,
                    true
                  )}
                </span>
              </div>
              <div>
                <label>Criado em</label>
                <span>{reportStatusState.data?.report?.created_at}</span>
              </div>
              <div>
                <label>Atualizado em</label>
                <span>{reportStatusState.data?.report?.updated_at}</span>
              </div>
              <div>
                <label>Enviado por e-mail</label>
                <span>
                  {["smart", "smart_pro", "mapa_calor", "docs"].includes(
                    reportStatusState.data?.report?.case_type
                  ) ? (
                    `não aplicável (${reportStatusState.data?.report?.case_type_display})`
                  ) : reportStatusState.data?.report?.was_sent_by_email ? (
                    <strong>Sim</strong>
                  ) : (
                    <strong>Não</strong>
                  )}
                </span>
              </div>
              <div>
                <label>Modo pagamento</label>
                <span>
                  {reportStatusState.data?.report?.billing_mode_display}
                </span>
              </div>
              <div>
                <label>Caso pago</label>
                <span>
                  {reportStatusState.data?.report?.billing_mode === "mensal" ? (
                    "Não aplicável (cliente mensal)"
                  ) : reportStatusState.data?.report?.is_paid ? (
                    <strong>Sim</strong>
                  ) : (
                    <strong>Não</strong>
                  )}
                </span>
              </div>
              <div>
                <label>Valor</label>
                <span>
                  {reportStatusState.data?.report?.price !== null
                    ? formatCurrency(reportStatusState.data?.report?.price)
                    : " - "}
                </span>
              </div>
              <div>
                <label>Enviado ao monday</label>
                <span>
                  {reportStatusState.data?.report?.monday_item_id ? (
                    <Link
                      to={`https://equipeinquest.monday.com/boards/3625535500/pulses/${reportStatusState.data?.report?.monday_item_id}`}
                      target={"_blank"}
                    >
                      Sim
                    </Link>
                  ) : (
                    "Não"
                  )}
                </span>
              </div>

              <br />
              <div>
                <h4>Dados cliente e usuário</h4>
              </div>
              <div>
                <label>ID Cliente</label>
                <span>{reportStatusState.data?.report?.client?.id}</span>
              </div>
              <div>
                <label>Cliente</label>
                <span>{reportStatusState.data?.report?.client?.name}</span>
              </div>
              <div>
                <label>E-mail</label>
                <span>{reportStatusState.data?.report?.client?.email}</span>
              </div>
              <div>
                <label>E-mail usuário</label>
                <span>
                  {reportStatusState.data?.report?.user?.email ?? " - "}
                </span>
              </div>
              <br />
            </div>
          </Col>
          <Col sm={24} xs={24} md={10}>
            <Card
              title="Histórico do caso"
              bordered={false}
              style={{ width: 450 }}
              className="report-logs"
            >
              <Timeline
                items={reportStatusState.data?.report?.logs?.map(
                  (log, idx) => ({
                    key: idx,
                    color:
                      log.type === "positive"
                        ? "green"
                        : log.type === "negative"
                        ? "red"
                        : "gray",
                    children: (
                      <div>
                        <div>{log.message}</div>
                        <div style={{ fontSize: "10px", color: "gray" }}>
                          {log.created_at}
                        </div>
                      </div>
                    ),
                  })
                )}
              />
            </Card>
          </Col>
        </Row>
        <Row justify={"end"} gutter={[16, 24]} className="report-header">
          <Col sm={24} xs={24} md={8} lg={8}>
          </Col>
          <Col sm={24} xs={24} md={10} lg={10}>
            <Card title="Notas fiscais" bordered={false} style={{ width: 450 }} className="report-logs">
              {reportStatusState.data?.nfs?.length > 0 ? 
              reportStatusState.data?.nfs?.map((nfs, idx) => (
                <div style={{ marginBottom: "10px" }}>
                  <div>
                    <strong>Número NF:</strong> {nfs.nfs_number ?? " - "}
                  </div>
                  <div>
                    <strong>Status:</strong>{" "}
                    {nfs.status_display ?? nfs.status ?? " - "}
                  </div>
                  <div>
                    <strong>Criada em:</strong> {nfs.created_at ?? " - "}
                  </div>
                  <div>
                    <strong>Emitido em:</strong> {nfs.emitted_at ?? " - "}
                  </div>
                  <div>
                    <strong>Link:</strong>
                    <Link to={nfs.link_file}>{nfs.link_file}</Link>
                  </div>
                  {nfs.error_message ? (
                    <div>
                      <strong>Mensagem de erro:</strong> {nfs.error_message}
                    </div>
                  ) : null}
                </div>
              )) : (
                <>Nenhuma nota fiscal foi emitida</>
              )}
            </Card>
          </Col>
        </Row>

        {reportStatusState.status.getReportStatus === StateStatus.succeeded ? (
          <ReportActionsMenu report={reportStatusState.data.report} />
        ) : (
          <div style={{ width: "100%", height: "150px" }}>
            <Skeleton active />
          </div>
        )}

        <div className="section-report-files">
          <Row style={{ width: "100%" }} justify={"start"} gutter={[16, 16]}>
            <Col sm={24} xs={24} md={12} lg={12}>
              <h2 style={{ padding: "15px" }}>Arquivos do caso</h2>

              <Card className="card-report-files">
                {reportStatusState.data?.report?.files?.length > 0 ? (
                  reportStatusState.data?.report?.files.map((file, idx) =>
                    file.type === "zip_xlsx" ||
                    (file.type === "xlsx" &&
                      reportStatusState.data?.report?.files.filter(
                        (x) => x.type === "zip_xlsx" && x.status === "completed"
                      ).length === 0) ? (
                      <RowFileReport
                        justify={"start"}
                        key={idx}
                        file={{
                          ...file,
                          type_display:
                            file.type === "xlsx" &&
                            reportStatusState.data?.report?.files.filter(
                              (x) =>
                                x.type === "xlsx_part" ||
                                (x.type === "zip_xlsx" &&
                                  x.status !== "completed")
                            ).length > 0
                              ? "xlsx PAGINADO"
                              : file.type,
                          status:
                            file.type === "xlsx" &&
                            reportStatusState.data?.report?.files.filter(
                              (x) =>
                                x.type === "xlsx_part" ||
                                (x.type === "zip_xlsx" &&
                                  x.status !== "completed")
                            ).length > 0
                              ? "loading"
                              : file.status,
                        }}
                        filename={file.filename}
                        isRebuildLoading={
                          reportStatusState.status.createReportFile ===
                            StateStatus.loading &&
                          reportStatusState.data.retryReportId ===
                            params.reportId
                        }
                        isDeleteLoading={
                          reportStatusState.status.deleteReportFile ===
                            StateStatus.loading &&
                          reportStatusState.data.deleteFileId === file.id
                        }
                        onClickRebuild={() => {
                          dispatch(
                            createReportFileThunk({
                              reportId: params.reportId,
                              type: file.type,
                            })
                          );
                        }}
                        onClickDelete={
                          file.type === "xlsx" &&
                          reportStatusState.data?.report?.files.filter(
                            (x) =>
                              x.type === "xlsx_part" ||
                              (x.type === "zip_xlsx" &&
                                x.status !== "completed")
                          ).length > 0
                            ? null
                            : () => {
                                dispatch(deleteReportFileThunk(file.id));
                              }
                        }
                      />
                    ) : null
                  )
                ) : ["docs", "mapa_calor"].includes(
                    reportStatusState.data?.report.case_type
                  ) ? (
                  <>
                    Nenhum arquivo anexado para o{" "}
                    {reportStatusState.data?.report.case_type_display}
                  </>
                ) : null}

                {!["docs", "mapa_calor"].includes(
                  reportStatusState.data?.report.case_type
                ) &&
                reportStatusState.data?.report?.files?.filter((x) =>
                  x.type?.includes("xlsx")
                ).length === 0 ? (
                  <>
                    {["xlsx"].map((type, idx) =>
                      reportStatusState.data?.report?.files?.filter(
                        (x) => x.type === type
                      ).length === 0 ? (
                        <RowFileReport
                          justify={"start"}
                          key={idx}
                          file={{ status: "idle", type }}
                          filename={null}
                          isRebuildLoading={
                            reportStatusState.status.createReportFile ===
                              StateStatus.loading &&
                            reportStatusState.data.retryReportId ===
                              params.reportId
                          }
                          isDeleteLoading={false}
                          onClickRebuild={() => {
                            dispatch(
                              createReportFileThunk({
                                reportId: params.reportId,
                                type,
                              })
                            );
                          }}
                        />
                      ) : null
                    )}
                  </>
                ) : null}
              </Card>
            </Col>
            <Col sm={24} xs={24} md={12}>
              <h2 style={{ padding: "15px" }}>Arquivos anexados</h2>

              <Card className="card-report-files">
                {reportStatusState.data?.report?.files?.length > 0 ? (
                  reportStatusState.data?.report?.files.map((file, idx) =>
                    !["xlsx", "zip_xlsx", "zip_pdf", "xlsx_part"].includes(
                      file.type
                    ) ? (
                      <RowFileReport
                        justify={"start"}
                        key={idx}
                        file={file}
                        filename={file.filename}
                        isRebuildLoading={
                          reportStatusState.status.createReportFile ===
                            StateStatus.loading &&
                          reportStatusState.data.retryReportId ===
                            params.reportId
                        }
                        isDeleteLoading={
                          reportStatusState.status.deleteReportFile ===
                            StateStatus.loading &&
                          reportStatusState.data.deleteFileId === file.id
                        }
                        onClickRebuild={() => {
                          dispatch(
                            createReportFileThunk({
                              reportId: params.reportId,
                              type: file.type,
                            })
                          );
                        }}
                        onClickDelete={() => {
                          dispatch(deleteReportFileThunk(file.id));
                        }}
                      />
                    ) : null
                  )
                ) : (
                  <Row
                    align={"middle"}
                    style={{ minHeight: "109px", color: "gray" }}
                  >
                    <Col>
                      <div>
                        Nenhum arquivo anexado para o{" "}
                        {reportStatusState.data?.report.case_type_display}
                      </div>
                    </Col>
                  </Row>
                )}
              </Card>
            </Col>
          </Row>
        </div>
        <div className="section-pesquisados">
          <Row justify={"start"}>
            <Col>
              <h2 style={{ padding: "15px" }}>Pesquisado(s)</h2>
            </Col>
          </Row>
          <Row>
            {reportStatusState.data?.report.case_type === "docs" ? (
              <Col sm={24} xs={24} md={12}>
                {reportStatusState.data?.report?.docs_products &&
                  reportStatusState.data?.report?.docs_products.map(
                    (doc, idx) => {
                      return (
                        <DocsStatusCard
                          key={idx}
                          doc={doc}
                          report={reportStatusState.data?.report}
                        />
                      );
                    }
                  )}
              </Col>
            ) : reportStatusState.data?.report.case_type === "mapa_calor" ? (
              <Col sm={24} xs={24} md={12}>
                {reportStatusState.data?.report?.targets &&
                  reportStatusState.data?.report?.targets?.map(
                    (target, idx) => {
                      return (
                        <DocsStatusCard
                          key={idx}
                          doc={target}
                          report={reportStatusState.data?.report}
                        />
                      );
                    }
                  )}
              </Col>
            ) : (
              <Col sm={24} xs={24} md={12}>
                {reportStatusState.data?.report?.targets &&
                  reportStatusState.data?.report?.targets?.map(
                    (target, idx) => {
                      return (
                        <TargetStatusCard
                          key={idx}
                          target={target}
                          report={reportStatusState.data?.report}
                        />
                      );
                    }
                  )}
              </Col>
            )}
          </Row>
        </div>
      </div>
    </>
  );
};

export default TargetStatusPage;
