import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import StateStatus from "../../utils/stateStatus"
import { getReportStatussStatus, getTargetStatusStatus, retryService, createFileTarget, closeReport, resendEmail, deleteReportFile, deleteFileTarget, editReportFields, createReportFile, nfseEmit, listNfse } from "./service"


export const getReportStatusThunk = createAsyncThunk("targetStatusPage/get-report", async (reportId) => {
    const response = await getReportStatussStatus(reportId)
    return response.data
})

export const getTargetStatusThunk = createAsyncThunk("targetStatusPage/get-target", async (targetId) => {
    const response = await getTargetStatusStatus(targetId)
    return response.data
})

export const closeReportThunk = createAsyncThunk("targetStatusPage/close", async (reportId) => {
    const response = await closeReport(reportId)
    return response.data
})

export const nfseEmitThunk = createAsyncThunk("targetStatusPage/nfse-emit", async (reportId) => {
    const response = await nfseEmit(reportId)
    return response.data
})

export const listNfseThunk = createAsyncThunk("targetStatusPage/list-nfse", async (reportId) => {
    const response = await listNfse(reportId)
    return response.data
})

export const resendEmailThunk = createAsyncThunk("targetStatusPage/resend-email", async (reportId) => {
    const response = await resendEmail(reportId)
    return response.data
})

export const retryServiceThunk = createAsyncThunk("targetStatusPage/retry", async (serviceId) => {
    const response = await retryService(serviceId)
    return response.data
})

export const createReportFileThunk = createAsyncThunk("targetStatusPage/create-report-files", async ({reportId, type}) => {
    const response = await createReportFile(reportId, type)
    return response.data
})

export const deleteReportFileThunk = createAsyncThunk("targetStatusPage/delete-report-file", async (fileId) => {
    const response = await deleteReportFile(fileId)
    return response.data
})

export const createFileTargetThunk = createAsyncThunk("targetStatusPage/create-target-file", async ({targetId, type}) => {
    const response = await createFileTarget(targetId, type)
    return response.data
})

export const deleteFileTargetThunk = createAsyncThunk("targetStatusPage/delete-target-file", async (fileId) => {
    const response = await deleteFileTarget(fileId)
    return response.data
})

export const editRefortFieldsThunk = createAsyncThunk("targetStatusPage/edit-report", async (data) => {
    const response = await editReportFields(data)
    return response.data
})





const initialState = {
    status: {
        getReportStatus: StateStatus.idle,
        retryService: StateStatus.idle,
        createReportFile: StateStatus.idle,
        deleteReportFile: StateStatus.idle,
        buildPdfTarget: StateStatus.idle,
        createFileTarget: StateStatus.idle,
        deleteFileTarget: StateStatus.idle,
        closeReport: StateStatus.idle,
        resendEmail: StateStatus.idle,
        editRefortFields: StateStatus.idle,
        nfseEmit: StateStatus.idle,
        listNfse: StateStatus.idle,
    },
    data: {
        retryServiceId: null,
        retryTargetId: null,
        deleteFileId: null,
        report: {targets: [], files: []},
        reportFilesUploading: [],
        nfs: [],
    },
    errMessage: {
        getReportStatus: null,
        retryService: null,
        createReportFile: null,
        buildPdfTarget: null,
        createFileTarget: null,
        resendEmail: null,
        editRefortFields: null,
        listNfse: null,
    }
}

export const targetStatusPageSlice = createSlice({
    name: "targetStatusPage",
    initialState,
    reducers: {
        resetStatus: (state) => {
            state.status.resendEmail = StateStatus.idle
            state.status.closeReport = StateStatus.idle
            state.status.createFileTarget = StateStatus.idle
            state.status.deleteFileTarget = StateStatus.idle
            state.status.deleteReportFile = StateStatus.idle
            state.status.createReportFile = StateStatus.idle
            state.status.buildPdfTarget = StateStatus.idle
            state.status.retryService = StateStatus.idle
            state.status.editRefortFields = StateStatus.idle
            state.status.nfseEmit = StateStatus.idle
        },
        setReportFilesUploading: (state, { payload }) => {
            console.log({state, payload})
            state.data.reportFilesUploading = payload
        },
        resetReportFilesUploading: (state) => {
            state.data.reportFilesUploading = []
        },
    },
    extraReducers: {

        // report thunk
        [getReportStatusThunk.pending]: (state, action) => {
            state.status.getReportStatus = StateStatus.loading
        },
        [getReportStatusThunk.fulfilled]: (state, action) => {
            let targets = state.data.report?.targets;

            if(state.data.report?.id === action.payload.id) {
                state.data.report = action.payload
                state.data.report.targets = targets
            } else {
                state.data.report = action.payload
            }

            state.status.getReportStatus = StateStatus.succeeded
            state.status.retryService = StateStatus.idle
        },
        [getReportStatusThunk.failed]: (state, action) => {
            state.status.getReportStatus = StateStatus.failed
            state.errMessage.getReportStatus = action.error.message
        },


        // target thunk
        [getTargetStatusThunk.pending]: (state, action) => {
            state.status.getTargetStatus = StateStatus.loading
        },
        [getTargetStatusThunk.fulfilled]: (state, action) => {
            const targets = []

            state.data.report?.targets?.forEach(target => {
                if(target.id === action.payload.id) {
                    targets.push(action.payload)
                } else {
                    targets.push(target)
                }
            })
            
            state.data.report.targets = targets
            state.status.getTargetStatus = StateStatus.succeeded
            state.status.retryService = StateStatus.idle
        },
        [getTargetStatusThunk.failed]: (state, action) => {
            state.status.getTargetStatus = StateStatus.failed
            state.errMessage.getTargetStatus = action.error.message
        },

        // close thunk
        [closeReportThunk.pending]: (state, action) => {
            state.status.closeReport = StateStatus.loading
        },
        [closeReportThunk.fulfilled]: (state, action) => {
            state.status.closeReport = StateStatus.succeeded
        },
        [closeReportThunk.failed]: (state, action) => {
            state.status.closeReport = StateStatus.failed
            state.errMessage.closeReport = action.error.message
        },

         // edit thunk
        [editRefortFieldsThunk.pending]: (state, action) => {
            state.status.editRefortFields = StateStatus.loading
        },
        [editRefortFieldsThunk.fulfilled]: (state, action) => {
            state.status.editRefortFields = StateStatus.succeeded
        },
        [editRefortFieldsThunk.failed]: (state, action) => {
            state.status.editRefortFields = StateStatus.failed
            state.errMessage.editRefortFields = action.error.message
        },

        

        // resend thunk
        [resendEmailThunk.pending]: (state, action) => {
            state.status.resendEmail = StateStatus.loading
        },
        [resendEmailThunk.fulfilled]: (state, action) => {
            state.status.resendEmail = StateStatus.succeeded
        },
        [resendEmailThunk.failed]: (state, action) => {
            state.status.resendEmail = StateStatus.failed
            state.errMessage.resendEmail = action.error.message
        },

        // retry service thunk
        [retryServiceThunk.pending]: (state, action) => {
            state.data.retryServiceId = action.meta.arg
            state.status.retryService = StateStatus.loading
        },
        [retryServiceThunk.fulfilled]: (state, action) => {
            state.status.retryService = StateStatus.succeeded
        },
        [retryServiceThunk.failed]: (state, action) => {
            state.status.retryService = StateStatus.failed
            state.errMessage.retryService = action.error.message
        },

        // build report file thunk
        [createReportFileThunk.pending]: (state, action) => {
            state.data.retryReportId = action.meta.arg.reportId
            state.status.createReportFile = StateStatus.loading
        },
        [createReportFileThunk.fulfilled]: (state, action) => {
            state.status.createReportFile = StateStatus.succeeded
        },
        [createReportFileThunk.failed]: (state, action) => {
            state.status.createReportFile = StateStatus.failed
            state.errMessage.createReportFile = action.error.message
        },

         // build report file thunk
         [nfseEmitThunk.pending]: (state, action) => {
            state.status.nfseEmit = StateStatus.loading
        },
        [nfseEmitThunk.fulfilled]: (state, action) => {
            state.status.nfseEmit = StateStatus.succeeded
        },
        [nfseEmitThunk.failed]: (state, action) => {
            state.status.nfseEmit = StateStatus.failed
            state.errMessage.nfseEmit = action.error.message
        },


        // list nfse file thunk
        [listNfseThunk.pending]: (state, action) => {
            state.status.listNfse = StateStatus.loading
        },
        [listNfseThunk.fulfilled]: (state, action) => {
            state.data.nfs = action.payload.items
            state.status.listNfse = StateStatus.succeeded
        },
        [listNfseThunk.failed]: (state, action) => {
            state.status.listNfse = StateStatus.failed
            state.errMessage.listNfse = action.error.message
        },



        // build target file thunk
        [createFileTargetThunk.pending]: (state, action) => {
            state.data.retryTargetId = action.meta.arg.targetId
            state.status.createFileTarget = StateStatus.loading
        },
        [createFileTargetThunk.fulfilled]: (state, action) => {
            state.status.createFileTarget = StateStatus.succeeded
        },
        [createFileTargetThunk.failed]: (state, action) => {
            state.status.createFileTarget = StateStatus.failed
            state.errMessage.createFileTarget = action.error.message
        },

        // delete report file thunk
        [deleteReportFileThunk.pending]: (state, action) => {
            state.data.deleteFileId = action.meta.arg
            state.status.deleteReportFile = StateStatus.loading
        },
        [deleteReportFileThunk.fulfilled]: (state, action) => {
            state.status.deleteReportFile = StateStatus.succeeded
        },
        [deleteReportFileThunk.failed]: (state, action) => {
            state.status.deleteReportFile = StateStatus.failed
            state.errMessage.deleteReportFile = action.error.message
        },


        // build target file thunk
        [deleteFileTargetThunk.pending]: (state, action) => {
            state.data.deleteFileId = action.meta.arg
            state.status.deleteFileTarget = StateStatus.loading
        },
        [deleteFileTargetThunk.fulfilled]: (state, action) => {
            state.status.deleteFileTarget = StateStatus.succeeded
        },
        [deleteFileTargetThunk.failed]: (state, action) => {
            state.status.deleteFileTarget = StateStatus.failed
            state.errMessage.deleteFileTarget = action.error.message
        },

    }
})


export const { resetStatus, setReportFilesUploading, resetReportFilesUploading } = targetStatusPageSlice.actions

export const selectReportStatusState = (state) => state.targetStatusPage
