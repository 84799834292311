import { Tooltip } from "antd"
import React from "react"
import { GraphPersonIcon } from "./components/GraphPersonIcon"
import { CircleCompanyIcon } from "./components/CircleCompanyIcon"

import { 
  FileExcelOutlined, 
  FilePdfOutlined, 
  ShareAltOutlined, 
  InfoCircleOutlined, 
  LeftCircleOutlined,
  DownCircleOutlined,
  ArrowDownOutlined,
  UpCircleOutlined,
  SyncOutlined,
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  PlusCircleOutlined,
  PlusOutlined,
  FileZipOutlined,
  DownloadOutlined,
  UserOutlined,
  EyeOutlined,
  SendOutlined,
  ShoppingCartOutlined,
  ClockCircleOutlined,
  SearchOutlined,
  EditOutlined,
  UserAddOutlined,
  CopyOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
  CloseCircleOutlined,
  PaperClipOutlined,
  FlagOutlined,
} from "@ant-design/icons"
import PropTypes from "prop-types"
import { WhatsappIcon } from "./components/WhatsappIcon"


const Icons = ({ icon, title, placement, className, ...props }) => {
  return (
    <Tooltip title={title} placement={placement}>
      <Icon
        icon={icon}
        className={`inquest-icon ${icon}-icon ${className ?? ""}`}
        {...props}
      />
    </Tooltip>
  )
}

export const Icon = ({ icon, ...props }) => {
  switch (icon) {
    case "graph-person":
      return <GraphPersonIcon {...props} />
    case "circle-company":
        return <CircleCompanyIcon {...props} />
    case "excel-file":
      return <FileExcelOutlined {...props} />
    case "pdf-file":
      return <FilePdfOutlined {...props} />
    case "zip-file":
      return <FileZipOutlined {...props} />
    case "clips":
      return <PaperClipOutlined {...props} />
    case "flag":
      return <FlagOutlined {...props} />  
    case "graph":
      return <ShareAltOutlined {...props} />
    case "info-circle":
      return <InfoCircleOutlined {...props} />
    case "left-arrow-circle":
      return <LeftCircleOutlined {...props} />
    case "down-arrow-circle":
      return <DownCircleOutlined {...props} />
    case "up-arrow-circle":
      return <UpCircleOutlined {...props} />
    case "down-arrow":
      return <ArrowDownOutlined {...props} />
    case "checked-circle":
      return <CheckCircleOutlined {...props} />
    case "circle-alert":
      return <ExclamationCircleOutlined {...props}/>
    case "circle-plus":
      return <PlusCircleOutlined {...props}/>
    case "plus":
      return <PlusOutlined {...props}/>
    case "copy":
      return <CopyOutlined {...props}/>
    case "loading":
      return <SyncOutlined {...props} />
    case "download":
      return <DownloadOutlined {...props} />
    case "user-profile":
      return <UserOutlined {...props} />
    case "whatsapp":
      return <WhatsappIcon {...props} />
    case "eye":
      return<EyeOutlined {...props} />
    case "delete":
      return <DeleteOutlined {...props} />
    case "add-user":
      return<UserAddOutlined {...props} />
    case "edit":
      return <EditOutlined {...props} />  
    case "send":
      return <SendOutlined {...props} />
    case "cart-shopping":
      return <ShoppingCartOutlined {...props} />
    case "question":
      return <QuestionCircleOutlined {...props} />  
    case "clock":
        return <ClockCircleOutlined {...props} />
    case "search":
        return <SearchOutlined {...props} />
    case "close-circle":
      return <CloseCircleOutlined {...props} />
    default:
      return <span>{icon}</span>
  }
}

Icons.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  className: PropTypes.string,
  placement: PropTypes.string,
}

Icon.propTypes = {
  icon: PropTypes.string,
}

export default Icons
